body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root,
body,
#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div[style*="clip-path"],
div[style*="clip-path"] + div[style*="transform"] {
  z-index: 9999 !important;
}
div[style*="clip-path"] + div[style*="transform"] {
  overflow: visible !important;
  margin-top: 20px;
}
